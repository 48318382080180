var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-layout',{attrs:{"flex":""}},[_c('v-text-field',{staticClass:"flex-shrink-1 flex-grow-0",attrs:{"label":"Search Shops","single-line":"","prepend-inner-icon":"mdi-magnify","hide-details":"","filled":"","loading":(_vm.search !== null || _vm.search !== '') && _vm.areShopsPending,"rounded":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}}),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary","to":{ name: 'edit-shop', params: { shopId: 'new' }}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Add Shop")],1)],1)],1),_c('v-data-table',{staticClass:"clickable",attrs:{"headers":_vm.headers,"loading":_vm.areShopsPending,"no-data-text":"No Shops Found","options":_vm.options,"server-items-length":_vm.haveShopsLoaded ? _vm.paginationData.default.mostRecent.total : 0,"items":_vm.shops,"footer-props":{ itemsPerPageOptions: [5,10,15] }},on:{"update:options":function($event){_vm.options=$event},"click:row":function (item) { return _vm.$router.push({name: 'shop', params: { shopId: item.id }}); }},scopedSlots:_vm._u([{key:"item.region_id",fn:function(ref){
var shop = ref.item;
return [_vm._v(_vm._s(shop.region.name))]}},{key:"item.usesOrdering",fn:function(ref){
var shop = ref.item;
return [_c('FeathersVuexCount',{attrs:{"service":"orders","params":{ query: {shop_id: shop.id, createdAt: {$gt: _vm.$day().subtract(2, 'months').toISOString()}} }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var total = ref.total;
return [(shop.usesOrdering && total)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]):_vm._e()]}}],null,true)})]}},{key:"item.usesInvoicing",fn:function(ref){
var shop = ref.item;
return [_c('FeathersVuexCount',{attrs:{"service":"invoices","params":{ query: {shop_id: shop.id, createdAt: {$gt: _vm.$day().subtract(2, 'months').toISOString()}} }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var total = ref.total;
return [(shop.usesInvoicing && total)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]):_vm._e()]}}],null,true)})]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"100"}},[_c('v-btn',{attrs:{"color":"primary","block":"","to":{ name: 'edit-shop', params: { shopId: 'new' }}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Add Shop")],1)],1)])]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }