<template lang="pug">
  v-container
    v-row
      v-col
        v-card(
          outlined
        )
          v-card-title
            v-layout(
              flex
            )
              v-text-field.flex-shrink-1.flex-grow-0(
                v-model.trim="search"
                label="Search Products"
                single-line
                prepend-inner-icon="mdi-magnify"
                hide-details
                filled
                :loading="(search !== null || search !== '') && areProductsPending"
                rounded
                dense
              )
              v-btn.ml-auto(
                color="primary"
                v-if="user.isAdmin"
                :to="{ name: 'edit-product', params: { productId: 'new' }}"
              )
                v-icon(
                  left
                ) mdi-plus
                | Add Global Product
          v-data-table.clickable(
            :headers="headers"
            :loading="areProductsPending"
            no-data-text="No Products Found"
            :options.sync="options"
            :server-items-length="haveProductsLoaded ? paginationData.default.mostRecent.total : 0"
            :items="products"
            @click:row="(item) => user.isAdmin ? $router.push({name: 'product', params: { productId: item.id }}) : null"
            :footer-props="{ itemsPerPageOptions: [5,10,15] }"
          )
            template(#body.prepend)
              tr
                td(colspan="100")
                  v-btn(
                    color="primary"
                    block
                    v-if="user.isAdmin"
                    :to="{ name: 'edit-product', params: { productId: 'new' }}"
                  )
                    v-icon(
                      left
                    ) mdi-plus
                    | Add Global Product

            template(v-slot:item.actions="{ item: product }" v-if="user.isAdmin")
              v-btn.pa-0(
                min-width="40px"
                @click.stop.prevent="(item) => user.isAdmin ? $router.push({name: 'edit-product', params: { productId: product.id }}) : null"
                color="white"
                elevation='0'
              )
                v-icon.ma-0(
                ) mdi-pencil

</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'GlobalProducts',
  setup (props, context) {
    const { Product } = context.root.$FeathersVuex.api

    // Setups up a reference that the search can v-model
    const search = ref(null)

    // Basic starter datatable attributes
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    })

    // Compute the sort based on various datatable properties
    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const queryObj = computed(() => {
      const returnQuery = {
        type: 'GLOBAL',
        $limit: limit.value,
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value
      }

      return returnQuery
    })

    // Construct the LOCAL params, these search the store
    const params = computed(() => {
      const params = { ...queryObj.value }

      if (search.value !== null && search.value !== '') {
        params.name = {
          $regex: '^' + search.value,
          $options: 'gi'
        }
      }

      return { query: { ...params } }
    })

    // Construct the fetch Params, these actually query the API, very confusing, I know
    const fetchParams = computed(() => {
      const params = { ...queryObj.value }

      if (search.value !== null && search.value !== '') {
        params.name = {
          $iRegexp: '^' + search.value
        }
      }

      return { query: { ...params } }
    })

    // Do the actual querying, be sure to remember to paginate
    const { items: products, isPending: areProductsPending, paginationData, haveLoaded: haveProductsLoaded } = useFind({
      model: Product,
      params,
      fetchParams,
      paginate: true
    })

    return {
      search,
      options,
      sortBy,
      limit,
      products,
      params,
      fetchParams,
      areProductsPending,
      haveProductsLoaded,
      paginationData
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Desc',
        value: 'description'
      },
      {
        text: 'Cost Price',
        value: 'costPrice'
      },
      {
        text: 'List Price',
        value: 'listPrice'
      },
      {
        text: 'Actions',
        value: 'actions'
      }
    ]
  })
}
</script>
