<template lang="pug">
  v-container
    v-row
      v-col
        v-card(
          outlined
        )
          v-card-title
            v-layout(
              flex
            )
              v-text-field.flex-shrink-1.flex-grow-0(
                v-model.trim="search"
                label="Search Techs"
                single-line
                prepend-inner-icon="mdi-magnify"
                hide-details
                filled
                :loading="(search !== null || search !== '') && areTechsPending"
                rounded
                dense
              )
              v-btn.ml-auto(
                color="primary"
                :to="{ name: 'edit-tech', params: { techId: 'new' }}"
              )
                v-icon(
                  left
                ) mdi-plus
                | Add Tech
          v-data-table.clickable(
            :headers="headers"
            :loading="areTechsPending"
            no-data-text="No Techs Found"
            :options.sync="options"
            :server-items-length="haveTechsLoaded ? paginationData.default.mostRecent.total : 0"
            :items="techs"
            @click:row="(item) => $router.push({name: 'tech', params: { techId: item.id }})"
            :footer-props="{ itemsPerPageOptions: [5,10,15] }"
          )
            template(v-slot:item.shop_id="{ item: tech }") {{ tech.shop.name }}
            template(#body.prepend)
              tr
                td(colspan="100")
                  v-btn(
                    color="primary"
                    block
                    :to="{ name: 'edit-tech', params: { techId: 'new' }}"
                  )
                    v-icon(
                      left
                    ) mdi-plus
                    | Add Tech

</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'Techs',
  setup (props, context) {
    const { Tech } = context.root.$FeathersVuex.api

    // Setups up a reference that the search can v-model
    const search = ref(null)

    // Basic starter datatable attributes
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    })

    // Compute the sort based on various datatable properties
    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const query = computed(() => {
      // Construct the base query
      const query = {
        $limit: limit.value,
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value
      }

      // Add a search term if it's not null, can $or multiple keys
      if (search.value !== null && search.value !== '') {
        query.$or = [
          {
            name: {
              $regex: '^' + search.value,
              $options: 'gi'
            }
          },
          {
            state: {
              $regex: '^' + search.value,
              $options: 'gi'
            }
          }
        ]
      }

      return query
    })

    // Construct the LOCAL params, these search the store
    const params = computed(() => {
      const params = { ...query.value }

      if (search.value !== null && search.value !== '') {
        params.name = {
          $regex: '^' + search.value,
          $options: 'gi'
        }
      }

      return { query: params }
    })

    // Construct the fetch Params, these actually query the API, very confusing, I know

    const fetchParams = computed(() => {
      const fetchParams = { ...query.value }

      if (search.value !== null && search.value !== '') {
        fetchParams.name = {
          $iRegexp: '^' + search.value
        }
      }

      return { query: { ...fetchParams, $eager: 'shop' } }
    })

    // Do the actual querying, be sure to remember to paginate
    const { items: techs, isPending: areTechsPending, paginationData, haveLoaded: haveTechsLoaded } = useFind({
      model: Tech,
      params,
      fetchParams,
      paginate: true
    })

    return {
      search,
      options,
      sortBy,
      limit,
      techs,
      query,
      params,
      fetchParams,
      areTechsPending,
      haveTechsLoaded,
      paginationData
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Shop',
        value: 'shop_id'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ]
  })
}
</script>
