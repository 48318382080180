<template lang="pug">
  v-container
    v-row
      v-col
        v-card(
          outlined
        )
          v-card-title
            v-layout(
              flex
            )
              v-text-field.flex-shrink-1.flex-grow-0(
                v-model="search"
                label="Search Regions"
                single-line
                prepend-inner-icon="mdi-magnify"
                hide-details
                filled
                :loading="(search !== null || search !== '') && areRegionsPending"
                rounded
                dense
              )
              v-btn.ml-auto(
                color="primary"
                :to="{ name: 'edit-region', params: { regionId: 'new' }}"
              )
                v-icon(
                  left
                ) mdi-plus
                | Add Region
          v-data-table.clickable(
            :headers="headers"
            :loading="areRegionsPending"
            no-data-text="No Regions Found"
            :options.sync="options"
            :server-items-length="haveRegionsLoaded ? paginationData.default.mostRecent.total : 0"
            :items="regions"
            @click:row="(item) => $router.push({name: 'region', params: { regionId: item.id }})"
            :footer-props="{ itemsPerPageOptions: [5,10,15] }"
          )
            template(v-slot:item.region_id="{ item: shop }") {{ shop.region.name }}
            template(#body.prepend)
              tr
                td(colspan="100")
                  v-btn(
                    color="primary"
                    block
                    :to="{ name: 'edit-region', params: { regionId: 'new' }}"
                  )
                    v-icon(
                      left
                    ) mdi-plus
                    | Add Region

</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'Regions',
  setup (props, context) {
    const { Region } = context.root.$FeathersVuex.api

    const search = ref(null)

    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    })

    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const queryObj = computed(() => {
      const returnQuery = {
        ...props.query,
        $limit: limit.value,
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value
      }

      return returnQuery
    })

    // Construct the LOCAL params, these search the store
    const params = computed(() => {
      const params = { ...queryObj.value }

      if (search.value !== null && search.value !== '') {
        params.name = {
          $regex: '^' + search.value,
          $options: 'gi'
        }
      }

      return { query: { ...params } }
    })

    // Construct the fetch Params, these actually query the API, very confusing, I know
    const fetchParams = computed(() => {
      const params = { ...queryObj.value }

      if (search.value !== null && search.value !== '') {
        params.name = {
          $iRegexp: '^' + search.value
        }
      }

      return { query: { ...params } }
    })

    const { items: regions, isPending: areRegionsPending, paginationData, haveLoaded: haveRegionsLoaded } = useFind({
      model: Region,
      params,
      fetchParams,
      paginate: true
    })

    return {
      search,
      options,
      sortBy,
      limit,
      regions,
      params,
      areRegionsPending,
      haveRegionsLoaded,
      paginationData
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Name',
        value: 'name'
      }
    ]
  })
}
</script>
